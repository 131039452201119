import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`22nd June 2020`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now specify the maximum lifespan of server-side connections using
`}<a parentName="p" {...{
            "href": "type://ServerBuilder#maxConnectionAge(Duration):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html#maxConnectionAge(java.time.Duration)"
          }}>{`type://ServerBuilder#maxConnectionAge(Duration)`}</a>{`. This is useful when you have to deal with a load balancer
without HTTP/2 support. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2747"
          }}>{`#2747`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2796"
          }}>{`#2796`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server server =
    Server.builder()
          .maxConnectionAge(Duration.ofMinutes(1))
          ...
          .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now record the name of the service that handled a request into `}<a parentName="p" {...{
            "href": "type://RequestOnlyLog#serviceName():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestOnlyLog.html#serviceName()"
          }}>{`type://RequestOnlyLog#serviceName()`}</a>{`.
`}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2768"
          }}>{`#2768`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2780"
          }}>{`#2780`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2809"
          }}>{`#2809`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2820"
          }}>{`#2820`}</a></p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`By using `}<a parentName="p" {...{
                "href": "type://ServiceBindingBuilder#defaultServiceName(String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceBindingBuilder.html#defaultServiceName(java.lang.String)"
              }}>{`type://ServiceBindingBuilder#defaultServiceName(String)`}</a>{`:`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`Server server =
    Server.builder()
          .route().path("/service")
                  .defaultServiceName("my-service")
                  .build(new MyService())
          .build();
`}</code></pre>
          </li>
          <li parentName="ul">
            <p parentName="li">{`By using `}<a parentName="p" {...{
                "href": "type://@ServiceName:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ServiceName.html"
              }}>{`type://@ServiceName`}</a>{`:`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`@ServiceName("my-service")
public class MyAnnotatedService {
    @Get("/get")
    public String get() { ... }

    @Post("/set")
    @ServiceName("my-post-service")
    public String post(@Param String value) { ... }
}
`}</code></pre>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Programmatically:`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`Server server =
    Server.builder()
          .service("/service", (ctx, req) -> {
              ctx.logBuilder().serviceName("my-service");
          })
          .build();
`}</code></pre>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Armeria will use the FQCN of the service class if you did not specify a service name.`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<inlineCode parentName="p">{`@Nullable`}</inlineCode>{` annotation to specify an optional parameter or request object in annotated
services. Previously, only the parameters with `}<a parentName="p" {...{
            "href": "type://@Default:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Default.html"
          }}>{`type://@Default`}</a>{` annotation or `}<inlineCode parentName="p">{`Optional`}</inlineCode>{` type were accepted.
`}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2773"
          }}>{`#2773`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`public class MyAnnotatedService {
    // null will be injected into 'value' instead of returning
    // '400 Bad Request' when 'value' is missing.
    @Get("/get")
    public String get(@Param @Nullable String value) { ... }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use the classes in the `}<inlineCode parentName="p">{`java.time`}</inlineCode>{` package in annotated services. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2760"
          }}>{`#2760`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2783"
          }}>{`#2783`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2792"
          }}>{`#2792`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2799"
          }}>{`#2799`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`public class MyAnnotatedService {
    @Get("/sleep/{duration}")
    public void sleep(@Param Duration duration) { ... }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now determine whether a request was successful or not from HTTP trailers in
`}<a parentName="p" {...{
            "href": "type://CircuitBreakerClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerClient.html"
          }}>{`type://CircuitBreakerClient`}</a>{` and `}<a parentName="p" {...{
            "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
          }}>{`type://RetryingClient`}</a>{` using `}<inlineCode parentName="p">{`onResponseTrailers()`}</inlineCode>{` method.
This can be useful when you work with gRPC, whose status code is encoded in the `}<inlineCode parentName="p">{`grpc-status`}</inlineCode>{` trailer. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2816"
          }}>{`#2816`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`CircuitBreaker cb = CircuitBreaker.of("my-service");
CircuitBreakerRule cbr =
    CircuitBreakerRule.builder()
                      .onResponseTrailers(trailers -> {
                          return trailers.getInt("grpc-status", -1) != 0;
                      })
                      .thenFailure()
                      .build();

MyServiceStub myService =
    Clients.builder("gproto+h2c://example.com/")
           .decorator(CircuitBreakerClient.newDecorator(cb, cbr))
           .build(MyServiceStub.class);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://RequestLog:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLog.html"
          }}>{`type://RequestLog`}</a>{` sanitizers now accept `}<a parentName="p" {...{
            "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
          }}>{`type://RequestContext`}</a>{` as an additional input, so that
the sanitizers can behave differently depending on the current path, etc. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2803"
          }}>{`#2803`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server server =
    Server.builder()
          .decorator(LoggingService.builder()
                                   .headersSanitizer((ctx, headers) -> {
                                       if (ctx.path().startsWith("/secret/")) {
                                           return "<secret>";
                                       } else {
                                           return headers;
                                       }
                                   })
                                   .newDecorator())
          ...
          .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Armeria now supports service discovery and registration for
`}<a parentName="p" {...{
            "href": "https://curator.apache.org/curator-x-discovery/index.html"
          }}>{`Curator Service Discovery`}</a>{` and
`}<a parentName="p" {...{
            "href": "http://stevenskelton.ca/finagle-serverset-clusters-using-zookeeper/"
          }}>{`Finagle ServerSets`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2673"
          }}>{`#2673`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2749"
          }}>{`#2749`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2791"
          }}>{`#2791`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`CuratorFramework curator = ...;
// Client side:
//// Curator Service Discovery:
EndpointGroup curatorEndpointGroup =
    ZooKeeperEndpointGroup.of(curator, "/discovery/curator",
                              ZooKeeperDiscoverySpec.curator("my-service"));
//// Finagle ServerSets:
EndpointGroup serverSetsEndpointGroup =
    ZooKeeperEndpointGroup.of(curator, "/discovery/serversets"
                              ZooKeeperDiscoverySpec.serverSets());

// Server-side:
Server server = ...;
//// Curator Service Discovery:
server.addListener(ZooKeeperUpdatingListener.of(
    curator, "/discovery/curator",
    ZooKeeperRegistrationSpec.curator("my-service")));
//// Finagle ServerSets:
server.addListener(ZooKeeperUpdatingListener.of(
    curator, "/discovery/serversets",
    ZooKeeperRegistrationSpec.serverSets()));
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now build `}<a parentName="p" {...{
            "href": "type://OAuth1aToken:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/auth/OAuth1aToken.html"
          }}>{`type://OAuth1aToken`}</a>{` more conveniently using the builder API. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2770"
          }}>{`#2770`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`OAuth1aToken token =
    OAuth1aToken.builder()
                .realm("...")
                .consumerKey("...")
                .token("...")
                ...
                .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The Spring Boot integration now hides all services under `}<inlineCode parentName="p">{`/internal/`}</inlineCode>{` for non-management ports when
`}<inlineCode parentName="p">{`management.server.port`}</inlineCode>{` property is set. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2408"
          }}>{`#2408`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2502"
          }}>{`#2502`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The Spring Boot integration now supports
`}<a parentName="p" {...{
            "href": "https://docs.spring.io/spring-boot/docs/2.3.0.RELEASE/reference/html/spring-boot-features.html#boot-features-graceful-shutdown"
          }}>{`the new graceful shutdown properties`}</a>{`
introduced in Spring Boot 2.3. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2784"
          }}>{`#2784`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2802"
          }}>{`#2802`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added a new API for handling reference counted or pooled objects such as `}<a parentName="p" {...{
            "href": "type://PooledHttpData"
          }}>{`type://PooledHttpData`}</a>{` in
a relatively safer way. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2448"
          }}>{`#2448`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`See `}<a parentName="li" {...{
              "href": "type://PooledHttpData"
            }}>{`type://PooledHttpData`}</a>{`, `}<a parentName="li" {...{
              "href": "type://PooledWebClient"
            }}>{`type://PooledWebClient`}</a>{`, `}<a parentName="li" {...{
              "href": "type://PooledHttpRequest"
            }}>{`type://PooledHttpRequest`}</a>{` and
`}<a parentName="li" {...{
              "href": "type://PooledHttpResponse"
            }}>{`type://PooledHttpResponse`}</a>{` for more information.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`Cleaned up minor issues reported by `}<a parentName="li" {...{
          "href": "https://errorprone.info/"
        }}>{`errorprone`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2772"
        }}>{`#2772`}</a></li>
      <li parentName="ul">{`Made some exception messages more user-friendly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2751"
        }}>{`#2751`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`It's now allowed to specify an absolute URL `}<em parentName="li">{`only when`}</em>{` a `}<a parentName="li" {...{
          "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
        }}>{`type://WebClient`}</a>{` was created `}<em parentName="li">{`without`}</em>{` a base
URL. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2757"
        }}>{`#2757`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`WebClient clientWithoutBaseUrl = WebClient.of();
WebClient clientWithBaseUrl = WebClient.of("https://example.com/");
// Good
clientWithBaseUrl.get("/bar");
clientWithoutBaseUrl.get("https://foo.com/");
// Bad
clientWithBaseUrl.get("https://foo.com/");
clientWithoutBaseUrl.get("/bar");
`}</code></pre>
      </li>
      <li parentName="ul">{`Boolean parameter conversion became more strict in annotated services. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2767"
        }}>{`#2767`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2774"
        }}>{`#2774`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Only `}<inlineCode parentName="li">{`true`}</inlineCode>{`, `}<inlineCode parentName="li">{`false`}</inlineCode>{`, `}<inlineCode parentName="li">{`1`}</inlineCode>{` and `}<inlineCode parentName="li">{`0`}</inlineCode>{` are accepted. Other values will cause a `}<inlineCode parentName="li">{`400 Bad Request`}</inlineCode>{` response.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{` web UI now shows the 'request body' field for `}<inlineCode parentName="li">{`DELETE`}</inlineCode>{` and `}<inlineCode parentName="li">{`PATCH`}</inlineCode>{` methods in the debug
form. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2756"
        }}>{`#2756`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2819"
        }}>{`#2819`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://JacksonRequestConverterFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/JacksonRequestConverterFunction.html"
        }}>{`type://JacksonRequestConverterFunction`}</a>{` now handles the case where the target type has a type parameter,
e.g. `}<inlineCode parentName="li">{`List<Long>`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2769"
        }}>{`#2769`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2779"
        }}>{`#2779`}</a></li>
      <li parentName="ul">{`Fixed a bug where the current `}<a parentName="li" {...{
          "href": "type://ServiceRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html"
        }}>{`type://ServiceRequestContext`}</a>{` is not pushed when invoking
`}<a parentName="li" {...{
          "href": "type://ResponseConverterFunction#convertResponse(ServiceRequestContext,ResponseHeaders,Object,HttpHeaders):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ResponseConverterFunction.html#convertResponse(com.linecorp.armeria.server.ServiceRequestContext,com.linecorp.armeria.common.ResponseHeaders,java.lang.Object,com.linecorp.armeria.common.HttpHeaders)"
        }}>{`type://ResponseConverterFunction#convertResponse(ServiceRequestContext,ResponseHeaders,Object,HttpHeaders)`}</a>{`.
`}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2789"
        }}>{`#2789`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://RequestContextExportingAppender:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logback/RequestContextExportingAppender.html"
        }}>{`type://RequestContextExportingAppender`}</a>{` now handles the `}<inlineCode parentName="li">{`<exports />`}</inlineCode>{` tag correctly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2781"
        }}>{`#2781`}</a></li>
      <li parentName="ul">{`Fixed a bug where some Reactive Streams `}<inlineCode parentName="li">{`Subscriber`}</inlineCode>{` implementations violate the specification. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2815"
        }}>{`#2815`}</a></li>
      <li parentName="ul">{`You no longer get sporadic `}<a parentName="li" {...{
          "href": "type://WriteTimeoutException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WriteTimeoutException.html"
        }}>{`type://WriteTimeoutException`}</a>{` from proxied connections. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2801"
        }}>{`#2801`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2805"
        }}>{`#2805`}</a></li>
      <li parentName="ul">{`You no longer get a `}<a parentName="li" {...{
          "href": "type://CancelledSubscriptionException:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/CancelledSubscriptionException.html"
        }}>{`type://CancelledSubscriptionException`}</a>{` unnecessarily when using
`}<a parentName="li" {...{
          "href": "type://PublisherBasedStreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/PublisherBasedStreamMessage.html"
        }}>{`type://PublisherBasedStreamMessage`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2797"
        }}>{`#2797`}</a></li>
      <li parentName="ul">{`You no longer get sporadic `}<inlineCode parentName="li">{`EncoderException`}</inlineCode>{`s from HTTP/1 connections. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2765"
        }}>{`#2765`}</a></li>
      <li parentName="ul">{`It's now disallowed to specify the following headers in gRPC `}<inlineCode parentName="li">{`Metadata`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2718"
        }}>{`#2718`}</a>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`:status`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`grpc-message`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`grpc-status`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`armeria.grpc.ThrowableProto-bin`}</inlineCode></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://AbstractUnwrappable#delegate()"
        }}>{`type://AbstractUnwrappable#delegate()`}</a>{` has been deprecated in favor of `}<a parentName="li" {...{
          "href": "type://Unwrappable#unwrap():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/Unwrappable.html#unwrap()"
        }}>{`type://Unwrappable#unwrap()`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`You can specify an absolute URL `}<em parentName="li">{`only when`}</em>{` a `}<a parentName="li" {...{
          "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
        }}>{`type://WebClient`}</a>{` was created `}<em parentName="li">{`without`}</em>{` a base URL.
It was previously allowed to specify an absolute URL even when created with a base URL. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2757"
        }}>{`#2757`}</a></li>
      <li parentName="ul">{`The method signature of
`}<a parentName="li" {...{
          "href": "type://RequestConverterFunction#convertRequest(ServiceRequestContext,AggregatedHttpRequest,Class,ParameterizedType):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/RequestConverterFunction.html#convertRequest(com.linecorp.armeria.server.ServiceRequestContext,com.linecorp.armeria.common.AggregatedHttpRequest,java.lang.Class,java.lang.reflect.ParameterizedType)"
        }}>{`type://RequestConverterFunction#convertRequest(ServiceRequestContext,AggregatedHttpRequest,Class,ParameterizedType)`}</a>{`
has been changed to support parameterized types. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2779"
        }}>{`#2779`}</a></li>
      <li parentName="ul">{`The default `}<a parentName="li" {...{
          "href": "type://MeterIdPrefixFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/metric/MeterIdPrefixFunction.html"
        }}>{`type://MeterIdPrefixFunction`}</a>{` returned by `}<a parentName="li" {...{
          "href": "type://MeterIdPrefixFunction#ofDefault(String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/metric/MeterIdPrefixFunction.html#ofDefault(java.lang.String)"
        }}>{`type://MeterIdPrefixFunction#ofDefault(String)`}</a>{`
now generates the meter IDs with different tags. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2780"
        }}>{`#2780`}</a>
        <ul parentName="li">
          <li parentName="ul">{`The `}<inlineCode parentName="li">{`route`}</inlineCode>{` tag has been replaced with the `}<inlineCode parentName="li">{`service`}</inlineCode>{` tag.`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`RetrofitMeterIdPrefixFunctionBuilder`}</inlineCode>{` has been removed and superseded by
`}<a parentName="li" {...{
          "href": "type://RequestOnlyLog#serviceName():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestOnlyLog.html#serviceName()"
        }}>{`type://RequestOnlyLog#serviceName()`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2780"
        }}>{`#2780`}</a></li>
      <li parentName="ul">{`ZooKeeper-based service discovery: `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2749"
        }}>{`#2749`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2791"
        }}>{`#2791`}</a>
        <ul parentName="li">
          <li parentName="ul">{`You now must specify `}<a parentName="li" {...{
              "href": "type://ZooKeeperRegistrationSpec:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/zookeeper/ZooKeeperRegistrationSpec.html"
            }}>{`type://ZooKeeperRegistrationSpec`}</a>{` or `}<a parentName="li" {...{
              "href": "type://ZooKeeperDiscoverySpec:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/zookeeper/ZooKeeperDiscoverySpec.html"
            }}>{`type://ZooKeeperDiscoverySpec`}</a>{` when creating
`}<a parentName="li" {...{
              "href": "type://ZooKeeperUpdatingListener:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/zookeeper/ZooKeeperUpdatingListener.html"
            }}>{`type://ZooKeeperUpdatingListener`}</a>{` or `}<a parentName="li" {...{
              "href": "type://ZooKeeperEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/zookeeper/ZooKeeperEndpointGroup.html"
            }}>{`type://ZooKeeperEndpointGroup`}</a>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`NodeValueCodec`}</inlineCode>{` has been removed.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`ZooKeeperEndpointGroupBuilder.codec()`}</inlineCode>{` and `}<inlineCode parentName="li">{`ZooKeeperUpdatingListenerBuilder.codec()`}</inlineCode>{` have been removed.`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`ByteBufHttpData`}</inlineCode>{` has been replaced with `}<a parentName="li" {...{
          "href": "type://PooledHttpData"
        }}>{`type://PooledHttpData`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2448"
        }}>{`#2448`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`HttpRequest.aggregateWithPooledObjects()`}</inlineCode>{` and `}<inlineCode parentName="li">{`HttpResponse.aggregateWithPooledObject`}</inlineCode>{` have been replaced
with `}<a parentName="li" {...{
          "href": "type://PooledHttpRequest"
        }}>{`type://PooledHttpRequest`}</a>{` and `}<a parentName="li" {...{
          "href": "type://PooledHttpResponse"
        }}>{`type://PooledHttpResponse`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2448"
        }}>{`#2448`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`SubscriptionOption.WITH_POOLED_OBJECTS`}</inlineCode>{` has been removed. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2448"
        }}>{`#2448`}</a></li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Bouncy Castle 1.65 → 1.65.01`}</li>
      <li parentName="ul">{`Dropwizard 2.0.9 → 2.0.10`}</li>
      <li parentName="ul">{`gRPC 1.29.0 → 1.30.1`}</li>
      <li parentName="ul">{`Jetty 9.4.29 → 9.4.30`}</li>
      <li parentName="ul">{`Reactor 3.3.5 → 3.3.6`}</li>
      <li parentName="ul">{`Spring Boot 2.3.0 → 2.3.1, 2.1.14 → 2.1.15`}</li>
      <li parentName="ul">{`Tomcat 9.0.35 → 9.0.36, 8.5.55 → 8.5.56`}</li>
      <li parentName="ul">{`Example dependencies`}
        <ul parentName="li">
          <li parentName="ul">{`Dagger 2.27 → 2.28`}</li>
          <li parentName="ul">{`grpc-kotlin-stub 0.1.2 → 0.1.3`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'cbornet', 'eonezhang', 'ghkim3221', 'heowc', 'huydx', 'ikhoon', 'jongmin92', 'joonhaeng', 'jrhee17', 'KarboniteKream', 'kojilin', 'max904-github', 'minwoox', 'okue', 'sokangmin', 'tobias-', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      